<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <user-list-edit
        :id="selectedId"
        :show-edit="showEdit"
        @hideShowEdit="hideShowEdit"
      />
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-b-toggle.sidebar-right
                variant="primary"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
              <user-list-add-new />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="table"
        :items="myProvider"
        :busy.sync="isBusy"
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        class="position-relative"
        responsive
        primary-key="id"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.logo"
                :text="avatarText(data.item.name)"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">#{{ data.item.id }}</small>
          </b-media>
        </template>

        <!-- Column: Phone -->
        <template #cell(phone)="data">
          {{ data.item.phone_number }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'view-user', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50"> View </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-toggle.sidebar-right-edit
              @click="sendInfo(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteUser(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
              >Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div
        v-if="loading"
        class="loader"
      />
    </b-card>
    <template>
      <b-row>
        <b-col md="6">
          <b-pagination
            :value="1"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-0 mt-1"
            prev-class="prev-item"
            next-class="next-item"
            @input="currentPage = $event"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import UserListAddNew from './UserListAddNew.vue'
import UserListEdit from './UserListEdit.vue'
// If you don't need the styles, do not connect
// eslint-disable-next-line import/no-extraneous-dependencies
Vue.use(VueSweetalert2)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    UserListAddNew,
    UserListEdit,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      rows: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      selectedId: 0,
      showEdit: false,
      loading: false,
    }
  },
  setup() {
    const tableColumns = [
      { key: 'name', sortable: true },
      { key: 'email', sortable: true },
      { key: 'phone', sortable: true },
      { key: 'actions' },
    ]

    return {
      // Filter
      avatarText,
      tableColumns,
    }
  },
  methods: {
    myProvider(ctx) {
      this.loading = true
      const promis = axios.get(`https://users-microsevice.muslimdo.com/api/internalops/user/list/${ctx.currentPage}`)
      return promis.then(res => {
        console.log(res)
        const items = res.data.data.data
        // this.rows = res.data.data.data
        // this.currentPage = res.data.data.pagination.current_page
        this.totalRows = res.data.data.pagination.total
        return items
      // eslint-disable-next-line no-unused-vars
      }).finally(() => {
        this.loading = false
      // eslint-disable-next-line no-unused-vars
      }).catch(error => [])
    },
    sendInfo(id) {
      this.selectedId = id
      this.showEdit = true
    },
    hideShowEdit() {
      this.showEdit = false
    },
    deleteUser(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`https://users-microsevice.muslimdo.com/api/internalops/user/${id}`).then(res => {
            this.$swal(
              'Deleted!',
              'User has been deleted.',
              'success',
            )
            this.$refs.table.refresh()
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
