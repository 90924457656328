<template>
  <div>
    <b-sidebar
      id="sidebar-right-edit"
      sidebar-class="sidebar-lg"
      title="Edit User"
      backdrop
      no-close-on-backdrop="true"
      right
      shadow
      no-header-close
      @click="showEdit"
    >
      <template>
        <validation-observer
          ref="refFormObserver"
        >
          <b-form
            class="p-2"
            @submit.enter.prevent=""
            @reset.prevent="resetForm"
          >
            <!-- First Name -->
            <validation-provider
              #default="validationContext"
              name="First Name"
              rules="required"
            >
              <b-form-group
                label="First Name"
                label-for="firstName"
              >
                <b-form-input
                  id="firstName"
                  v-model="formData.profile.first_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="First Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Last Name -->
            <validation-provider
              #default="validationContext"
              name="Last Name"
              rules="required"
            >
              <b-form-group
                label="Last Name"
                label-for="lastName"
              >
                <b-form-input
                  id="lastName"
                  v-model="formData.profile.last_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="First Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Mobile Number -->
            <validation-provider
              #default="validationContext"
              name="Mobile Number"
              rules="required"
            >
              <b-form-group
                label="Mobile Number"
                label-for="mobile-number"
              >
                <b-form-input
                  id="mobile-number"
                  v-model="formData.phone_number"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--! Password -->
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="formData.password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false:null"
                  name="login-password"
                  placeholder="Password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="updateUser()"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="cancel()"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { BoldIcon } from 'vue-feather-icons'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    BSidebar,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,

    // form valodation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    hideShowEdit: {
      type: Function,
      default: () => 1,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      variant: 'dark',
      required,
      alphaNum,
      email,
      show: true,
      passwordFieldType: 'password',
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        password: '',
        id: this.id,
      },
    }
  },
  updated() {
    this.getUser()
    this.updateUser()
  },
  methods: {
    cancel() {
      this.formData = {}
      this.$emit('hideShowEdit')
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
    },
    getUser() {
      axios.get(`https://users-microsevice.muslimdo.com/api/internalops/user/${this.id}`).then(res => {
        this.formData = res.data.data
      })
    },
    updateUser() {
      // eslint-disable-next-line no-unused-vars
      axios.post(`https://users-microsevice.muslimdo.com/api/internalops/user/${this.id}`, this.formData).then(res => {
        this.cancel()
        this.show = false
        this.formData = {}
        this.$swal(
          'Added!',
          'User has been Updated.',
          'success',
        )
        this.$parent.$refs.table.refresh()
        // eslint-disable-next-line no-empty
      }).catch(err => {
        console.log(err)
      })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.form-main {
    width: 79%;
    margin: auto;
}
</style>
